// Entry point for the build script in your package.json
import bootstrap from 'bootstrap'
// import 'jquery'
// import "jquery/dist/jquery.min"
require("@rails/ujs").start()
import jquery from 'jquery';
window.$ = window.jquery = jquery;
import '@tabler/core/dist/js/tabler.min.js'
// import tabler from "@tabler/core"
import "./controllers"
import "../assets/javascripts/serviceworker-companion"
import '@selectize/selectize'